export const Base_www = (string = "") => {
  if (string.indexOf("/www") !== -1) {
    return string.substr(4);
  }

  return string;
};

export const getFilename = (string = "") => {
  let filename = string.split("?")[0];
  filename = filename.substring(filename.lastIndexOf("/") + 1);
  // console.log(filename);
  return filename
};
