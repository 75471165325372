import React, { Component } from "react";
import routers from "../config/Router";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

class Index extends Component {
  state = {};
  render() {
    return (
      <>
        <Router basename="/">
          <React.Suspense fallback>
            <Switch>
              {routers.map((url, i) => {
                return url.layout ? (
                  <Route
                    key={i}
                    path={url.path}
                    exact={url.exact}
                    name={url.name}
                    render={(props) => <url.layout {...props} />}
                  />
                ) : (
                  <h1>Page Not Found</h1>
                );
              })}
              <h1>Page Not Found</h1>
            </Switch>
          </React.Suspense>
        </Router>
      </>
    );
  }
}

export default Index;
