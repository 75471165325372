import React from "react";
import ruth from "./Ruth.json";
import { Base_www } from "./Helper";

var router = [];
ruth["router"].map((items, i) => {
  return (router[i] = {
    path: Base_www(items.path),
    exact: items.exact,
    layout: React.lazy(() => import(`../layout/${items.layout}`)),
    component: React.lazy(() => import(`../pages/${items.component}`)),
    name: items.name,
  });
});

export default router;
